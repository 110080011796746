<template lang="pug">
div(:id="item.uid" @mousedown="preventDragIfNotBaseMode")
  .om-pick-a-present-title(v-if="item.data.title.active")
    div {{ item.data.title.text }}
  .om-pick-a-present-subtitle(v-if="item.data.subtitle.active")
    div {{ item.data.subtitle.text }}
  .om-pick-a-present
    .om-present(v-for="i in presentCount" :key="`present-${i}`" :data-present-key="i")
      present-svg
      .om-present-coupon-holder
        div {{ $t('pickAPresent.defaults.coupon') }}
</template>

<script>
  import PresentSvg from '@/editor/components/svg/Present.vue';
  import readOnlyElementMixin from '@/editor/mixins/readOnlyElement';

  export default {
    components: {
      PresentSvg,
    },
    mixins: [readOnlyElementMixin],

    props: ['item'],

    computed: {
      presentCount() {
        return this.item.data.presentCount;
      },
    },
  };
</script>
