import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['templateSaveData']),
  },

  methods: {
    preventDragIfNotBaseMode(e) {
      if (this.templateSaveData.type !== 'base') {
        e.preventDefault();
      }
    },
  },
};
